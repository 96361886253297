.mySwiper {
  width: 100%;
  /* height: 530px; */
  /* background-color: #f1f1f1; */
}

.mySwiper .swiper-slide {
  transition: none!important;
}

.mySwiper .swiper-button-prev, .mySwiper .swiper-button-next {
  color: gray!important;
}

.mySwiper .swiper-pagination-bullet {
  background-color: gray!important;
}

.mySwiper .swiper-pagination-bullet-active {
  background-color: gray!important;
}

.mySwiper:active {
  cursor: grab;
}

.mySwiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper .swiper-slide img {
  object-fit: fill;
}

@media (max-width: 768px) {
  .mySwiper {
    height: 415px;
  }
}

@media (max-width: 576px) {
  .mySwiper {
    height: 315px;
  }
}


.mySwiper2 {
  width: 100%;
  height: 200px;
}

.mySwiper3 {
  width: 100%;
  height: 200px;
  margin-top: 2em;
}

.mySwiper3 .swiper-slide {
  background-color: #edf0f1;
  border-radius: 10px;
}